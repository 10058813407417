import atreaPic from "@public/static/projects/atrea.jpg";
import besttourPic from "@public/static/projects/best-tour.jpg";
import expedicePic from "@public/static/projects/expedice.jpg";
import eonPic from "@public/static/projects/eon.jpg";
import giritonPic from "@public/static/projects/giriton.jpg";
import setriceleceskoPic from "@public/static/projects/setri-cele-cesko.jpg";
import kalkulatorPic from "@public/static/projects/kalkulator.jpg";
import klaskaPic from "@public/static/projects/klaska.jpg";
import mujDumPic from "@public/static/projects/muj-dum.jpg";
import novaenergiePic from "@public/static/projects/nova-energie.jpg";
import obnovitelnePic from "@public/static/projects/obnovitelne.jpg";
import oskenujto from "@public/static/projects/oskenujto.jpg";
import rehaTabPic from "@public/static/projects/reha-tab.jpg";
import refinansoPic from "@public/static/projects/refinanso.jpg";
import oreaPic from "@public/static/projects/orea.jpg";
import jablonecPic from "@public/static/projects/jablonec.jpg";
import sectenoPic from "@public/static/projects/secteno.jpg";
import shopsysCommerceCloudPic from "@public/static/projects/shopsys.jpg";
import sousedePic from "@public/static/projects/sousede.jpg";
import slcrPic from "@public/static/projects/slcr.jpg";
import tenisDetem from "@public/static/projects/tenis-detem.jpg";
import tennisAcademies from "@public/static/projects/tennis-academies.jpg";
import tescomaPic from "@public/static/projects/tescoma.jpg";
import waxtipPic from "@public/static/projects/waxtip.jpg";
import wifimbPic from "@public/static/projects/wifimb.jpg";
import { StaticImageData } from "next/image";
import { HASHTAGS, HashTagType } from "@config/hashtags-config";

export type ProjectId =
    | "kalkulator"
    | "expedice"
    | "mujDum"
    | "jablonec"
    | "orea"
    | "klaska"
    | "atrea"
    | "slcr"
    | "rehaTab"
    | "setricelecesko"
    | "sousede"
    | "novaEnergie"
    | "tenisDetem"
    | "refinanso"
    | "eon"
    | "secteno"
    | "obnovitelne"
    | "waxtip"
    | "wifimb"
    | "besttour"
    | "tennisAcademies"
    | "oskenujTo"
    | "giriton"
    | "tescoma"
    | "shopsysCommerceCloud";

export interface Project {
    id: ProjectId;
    description: string;
    hashtags: HashTagType[];
    imageSrc: StaticImageData;
    name: string;
    url: string;
    hoverColor?: `#${string}`;
}

export const PROJECTS: Project[] = [
    {
        id: "kalkulator",
        description: "reference:section.kalkulator.text",
        hashtags: [
            HASHTAGS.uxDesign,
            HASHTAGS.development,
            HASHTAGS.marketing,
            HASHTAGS.seo,
            HASHTAGS.product,
            HASHTAGS.integrations,
        ],
        imageSrc: kalkulatorPic,
        name: "reference:section.kalkulator.title",
        url: "/reference/kalkulator",
        hoverColor: "#06514C",
    },
    {
        id: "orea",
        description: "reference:section.orea.text",
        hashtags: [HASHTAGS.uxDesign, HASHTAGS.development, HASHTAGS.product, HASHTAGS.integrations],
        imageSrc: oreaPic,
        name: "reference:section.orea.title",
        url: "/reference/orea",
        hoverColor: "#D2B94B",
    },
    {
        id: "jablonec",
        description: "reference:section.jablonec.text",
        hashtags: [HASHTAGS.product, HASHTAGS.development, HASHTAGS.uxDesign, HASHTAGS.integrations],
        imageSrc: jablonecPic,
        name: "reference:section.jablonec.title",
        url: "/reference/jablonec",
        hoverColor: "#EBF9F9",
    },
    {
        id: "slcr",
        description: "reference:section.slcr.text",
        hashtags: [HASHTAGS.uxDesign, HASHTAGS.development, HASHTAGS.product, HASHTAGS.integrations],
        imageSrc: slcrPic,
        name: "reference:section.slcr.title",
        url: "/reference/slcr",
        hoverColor: "#2D5EA9",
    },
    {
        id: "tenisDetem",
        description: "reference:section.tenisDetem.text",
        hashtags: [
            HASHTAGS.product,
            HASHTAGS.uxDesign,
            HASHTAGS.branding,
            HASHTAGS.development,
            HASHTAGS.marketing,
            HASHTAGS.seo,
        ],
        imageSrc: tenisDetem,
        name: "reference:section.tenisDetem.title",
        url: "/reference/tenis-detem",
        hoverColor: "#1DACBC",
    },
    {
        id: "atrea",
        description: "reference:section.atrea.text",
        hashtags: [HASHTAGS.uxDesign, HASHTAGS.development, HASHTAGS.product, HASHTAGS.integrations],
        imageSrc: atreaPic,
        name: "reference:section.atrea.title",
        url: "/reference/atrea",
        hoverColor: "#00418E",
    },
    {
        id: "rehaTab",
        description: "reference:section.rehaTab.text",
        hashtags: [
            HASHTAGS.uxDesign,
            HASHTAGS.development,
            HASHTAGS.marketing,
            HASHTAGS.seo,
            HASHTAGS.product,
            HASHTAGS.integrations,
        ],
        imageSrc: rehaTabPic,
        name: "reference:section.rehaTab.title",
        url: "/reference/reha-tab",
        hoverColor: "#1F2937",
    },
    {
        id: "sousede",
        description: "reference:section.sousede.text",
        hashtags: [
            HASHTAGS.product,
            HASHTAGS.uxDesign,
            HASHTAGS.development,
            HASHTAGS.marketing,
            HASHTAGS.integrations,
            HASHTAGS.business,
        ],
        imageSrc: sousedePic,
        name: "reference:section.sousede.title",
        url: "/reference/sousede",
        hoverColor: "#33D2B2",
    },
    {
        id: "expedice",
        description: "reference:section.expedice.text",
        hashtags: [HASHTAGS.product, HASHTAGS.development, HASHTAGS.uxDesign, HASHTAGS.management],
        imageSrc: expedicePic,
        name: "reference:section.expedice.title",
        url: "/reference/energeticka-expedice",
        hoverColor: "#FCEFEE",
    },
    {
        id: "mujDum",
        description: "reference:section.mujDum.text",
        hashtags: [HASHTAGS.product, HASHTAGS.development, HASHTAGS.uxDesign, HASHTAGS.management],
        imageSrc: mujDumPic,
        name: "reference:section.mujDum.title",
        url: "",
        hoverColor: "#FFF",
    },
    {
        id: "refinanso",
        description: "reference:section.refinanso.text",
        hashtags: [HASHTAGS.development],
        imageSrc: refinansoPic,
        name: "reference:section.refinanso.title",
        url: "/reference/refinanso",
        hoverColor: "#0C0055",
    },
    {
        id: "klaska",
        description: "reference:section.klaska.text",
        hashtags: [HASHTAGS.development, HASHTAGS.seo],
        imageSrc: klaskaPic,
        name: "reference:section.klaska.title",
        url: "/reference/klaska-ltd",
        hoverColor: "#D0D0D080",
    },
    {
        id: "tescoma",
        description: "reference:section.tescoma.text",
        hashtags: [HASHTAGS.development],
        imageSrc: tescomaPic,
        name: "reference:section.tescoma.title",
        url: "/reference/tescoma",
        hoverColor: "#DD052E",
    },
    {
        id: "eon",
        description: "reference:section.eon.text",
        hashtags: [HASHTAGS.uxDesign, HASHTAGS.development],
        imageSrc: eonPic,
        name: "reference:section.eon.title",
        url: "/reference/eon-virtualni-baterie",
    },
    {
        id: "obnovitelne",
        description: "reference:section.obnovitelne.text",
        hashtags: [HASHTAGS.uxDesign, HASHTAGS.development, HASHTAGS.seo],
        imageSrc: obnovitelnePic,
        name: "reference:section.obnovitelne.title",
        url: "/reference/obnovitelne",
        hoverColor: "#07274D",
    },
    {
        id: "giriton",
        description: "reference:section.giriton.text",
        hashtags: [HASHTAGS.uxDesign, HASHTAGS.development],
        imageSrc: giritonPic,
        name: "reference:section.giriton.title",
        url: "/reference/giriton",
        hoverColor: "#41B971",
    },
    {
        id: "shopsysCommerceCloud",
        description: "reference:section.shopsysCommerceCloud.text",
        hashtags: [HASHTAGS.development],
        imageSrc: shopsysCommerceCloudPic,
        name: "reference:section.shopsysCommerceCloud.title",
        url: "/reference/shopsys-commerce-cloud",
    },
    {
        id: "setricelecesko",
        description: "reference:section.setriCeleCesko.text",
        hashtags: [HASHTAGS.product, HASHTAGS.development, HASHTAGS.uxDesign],
        imageSrc: setriceleceskoPic,
        name: "reference:section.setriCeleCesko.title",
        url: "/reference/setri-cele-cesko",
    },
    {
        id: "wifimb",
        description: "reference:section.wifimb.text",
        hashtags: [HASHTAGS.product, HASHTAGS.uxDesign, HASHTAGS.development],
        imageSrc: wifimbPic,
        name: "reference:section.wifimb.title",
        url: "/reference/wifi-mb",
        hoverColor: "#EE7B08",
    },
    {
        id: "secteno",
        description: "reference:section.secteno.text",
        hashtags: [HASHTAGS.uxDesign, HASHTAGS.development, HASHTAGS.integrations],
        imageSrc: sectenoPic,
        name: "reference:section.secteno.title",
        url: "/reference/secteno",
        hoverColor: "#388358",
    },
    {
        id: "besttour",
        description: "reference:section.besttour.text",
        hashtags: [HASHTAGS.uxDesign, HASHTAGS.development],
        imageSrc: besttourPic,
        name: "reference:section.besttour.title",
        url: "/reference/interni-system-pro-best-tour",
        hoverColor: "#E50017",
    },
    {
        id: "novaEnergie",
        description: "reference:section.novaenergie.text",
        hashtags: [HASHTAGS.uxDesign, HASHTAGS.development],
        imageSrc: novaenergiePic,
        name: "reference:section.novaenergie.title",
        url: "/reference/nova-energie-ceska",
    },
    {
        id: "waxtip",
        description: "reference:section.waxtip.text",
        hashtags: [HASHTAGS.product, HASHTAGS.uxDesign, HASHTAGS.development],
        imageSrc: waxtipPic,
        name: "reference:section.waxtip.title",
        url: "/reference/waxtip",
        hoverColor: "#EA3335",
    },
];

export const PERSONAL_PROJECTS: Project[] = [
    {
        id: "tennisAcademies",
        description: "reference:section.personal.tennisAcademies.text",
        hashtags: [HASHTAGS.development, HASHTAGS.uxDesign, HASHTAGS.marketing],
        imageSrc: tennisAcademies,
        name: "reference:section.personal.tennisAcademies.title",
        url: "https://tennis-academies.com",
        hoverColor: "#01A992",
    },
    {
        id: "oskenujTo",
        description: "reference:section.personal.oskenujto.text",
        hashtags: [HASHTAGS.product, HASHTAGS.uxDesign, HASHTAGS.development, HASHTAGS.marketing],
        imageSrc: oskenujto,
        name: "reference:section.personal.oskenujto.title",
        url: "http://oskenujto.cz",
        hoverColor: "#4834AF",
    },
];
