import { Button } from "@ui/components/button/button";
import { TextLink } from "@ui/components/text-link/text-link";
import React, { memo } from "react";
import { Trans, useTranslation } from "next-i18next";
import { useLocale } from "@translations/locale-context";

interface Props {
    title?: string;
}

export const CollaborateSection = memo<Props>(({ title }) => {
    const { t } = useTranslation();
    const locale = useLocale();
    const isEn = locale === "en";

    return (
        <section className="w-full bg-uxfViolet">
            <div className="ui-container h-full space-y-10 divide-y divide-neutral-600 py-20 text-white md:space-y-20 md:py-32">
                <h2 className="ui-typo-h1 max-w-4xl">
                    <Trans
                        i18nKey={title ?? "collaborate.title"}
                        t={t}
                        components={{
                            highlight: <span className="text-uxfOrange" />,
                        }}
                    />
                </h2>
                <div className="space-y-10 pt-10 sm:grid sm:grid-cols-2 sm:grid-rows-2 sm:gap-8 sm:space-y-0 md:pt-20 lg:flex lg:items-center lg:justify-between lg:space-y-0">
                    <div>
                        <p className="ui-typo-overline-small mb-5 font-bold opacity-70">{t("collaborate.phone")}</p>
                        <TextLink className="ui-typo-medium whitespace-nowrap" href="tel:+420777753897" variant="white">
                            +420 777 753 897
                        </TextLink>
                    </div>
                    <div>
                        <p className="ui-typo-overline-small mb-5 font-bold opacity-70">{t("collaborate.email")}</p>
                        <TextLink className="ui-typo-medium" href="mailto:fruhbauer@uxf.cz" variant="white">
                            fruhbauer@uxf.cz
                        </TextLink>
                    </div>
                    <div className="pt-6 sm:pt-2 lg:pt-0">
                        <Button
                            className="ui-typo-body col-span-2 h-fit w-full font-bold sm:w-fit"
                            href={isEn ? "/en/book-consultation" : "/sjednat-konzultaci"}
                            variant="orange"
                        >
                            {t("collaborate.button")}
                        </Button>
                    </div>
                </div>
            </div>
        </section>
    );
});

CollaborateSection.displayName = "CollaborateSection";
