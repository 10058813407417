import { ResizerImageProps, resizerImageUrl, StaticImageData } from "@uxf/core/utils/resizer";
import { FC } from "react";
import { breakpoints } from "@ui/theme/breakpoints";
import { em } from "@uxf/styles/units/em";

type Props = {
    className?: string;
    imgClassName?: string;
    src: string | StaticImageData;
    alt: string;
    widths?: number[];
    heights?: number[];
} & (
    | {
          width: number;
          height: number;
      }
    | {
          width?: never;
          height: number;
      }
    | {
          width: number;
          height?: never;
      }
);

type SourceProps = {
    width: number | "auto";
    height: number | "auto";
    src: string | StaticImageData;
};

type ResponsiveSourceProps = {
    width: number | "auto";
    height: number | "auto";
    src: string | StaticImageData;
    index: number;
};

function getSrcSet(
    src: string | StaticImageData,
    height: number | "auto",
    width: number | "auto",
    options: ResizerImageProps,
    hiDpi = true,
) {
    if (!hiDpi) {
        return resizerImageUrl(src, width, height, options);
    }

    return `${resizerImageUrl(
        src,
        typeof width === "number" ? width * 2 : width,
        typeof height === "number" ? height * 2 : height,
        options,
    )} 2x, ${resizerImageUrl(src, width, height, options)}`;
}

function getHeightFromRatio(width: number, aspectRatio: number) {
    return Math.ceil(aspectRatio * width);
}

const Source = ({ height, src, width, ...restProps }: SourceProps) => (
    <>
        <source srcSet={getSrcSet(src, height, width, { ...restProps, toFormat: "avif" })} type="image/avif" />
        <source srcSet={getSrcSet(src, height, width, { ...restProps, toFormat: "webp" })} type="image/webp" />
        <source srcSet={getSrcSet(src, height, width, { ...restProps })} />
    </>
);

const ResponsiveSource = ({ height, index, src, width, ...restProps }: ResponsiveSourceProps) => (
    <>
        <source
            media={index !== 0 ? `(min-width: ${em(Object.values(breakpoints)[index])})` : undefined}
            srcSet={getSrcSet(src, height, width, {
                ...restProps,
                toFormat: "avif",
            })}
            type="image/avif"
        />
        <source
            media={index !== 0 ? `(min-width: ${em(Object.values(breakpoints)[index])})` : undefined}
            srcSet={getSrcSet(src, height, width, {
                ...restProps,
                toFormat: "webp",
            })}
            type="image/webp"
        />
        <source
            media={index !== 0 ? `(min-width: ${em(Object.values(breakpoints)[index])})` : undefined}
            srcSet={getSrcSet(src, height, width, restProps)}
        />
    </>
);

export const StaticImage: FC<Props> = ({ className, src, width, height, alt, imgClassName, widths, heights }) => (
    <picture className={className}>
        {widths ? (
            widths
                .map(
                    (w, i) =>
                        i < Object.values(breakpoints).length && (
                            <ResponsiveSource
                                height={
                                    heights && heights[i]
                                        ? heights[i]
                                        : width && height
                                        ? getHeightFromRatio(w, height / width)
                                        : "auto"
                                }
                                index={i}
                                key={`${i}-${w}`}
                                src={src}
                                width={w}
                            />
                        ),
                )
                .reverse()
        ) : (
            <Source width={width ?? "auto"} height={height ?? "auto"} src={src} />
        )}
        <img
            alt={alt}
            className={imgClassName}
            height={height}
            src={resizerImageUrl(src, width ?? "auto", height ?? "auto")}
            width={width}
        />
    </picture>
);
